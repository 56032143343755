// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
    width: 450px;
    height: 500px;
    margin: 0 auto;
    animation: fade-in 2s ease-in-out;
    margin-top: 70px;
    margin-bottom: -300px;
}
@media (max-width: 700px) {
    .chart-container {
        width: 300px;
        height: 350px;
    }
}`, "",{"version":3,"sources":["webpack://./src/predictionChart.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,iCAAiC;IACjC,gBAAgB;IAChB,qBAAqB;AACzB;AACA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;AACJ","sourcesContent":[".chart-container {\n    width: 450px;\n    height: 500px;\n    margin: 0 auto;\n    animation: fade-in 2s ease-in-out;\n    margin-top: 70px;\n    margin-bottom: -300px;\n}\n@media (max-width: 700px) {\n    .chart-container {\n        width: 300px;\n        height: 350px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
